<template>
    <base-card class="pa-4 mb-4">
        <v-card-title class="pa-1 pl-3 font-weight-bold">Advance Filter</v-card-title>
        <v-row class="ma-0 pa-0">
            <v-col cols="4">
                <v-menu v-model="menu" transition="scale-transition"
                    :close-on-content-click="false" offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-tooltip top :disabled="donateDateClr">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto" @input="clearDate($event)"
                                        :value="donateDateText"
                                        label="Donation Date/Time"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <span>{{donateDateTooltip}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <div class="range-calendar">
                        <vc-date-picker mode="dateTime" class="date-picker-range" v-model="donateDate" is-range :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                            timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"/>                           
                        <v-btn class="ma-2"  dark color="primary" @click="donateDateClr=menu=false;">
                            Ok
                        </v-btn>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="6">
                    <v-btn type="button" class="ma-2" dark color="info" @click="!donateDateClr && resetModel()">
                        <v-icon left>mdi-refresh</v-icon>
                        Reset
                    </v-btn>
                    <v-btn type="button" class="ma-2" dark color="primary" @click="!donateDateClr && searchFilter()">
                        <v-icon small left>mdi-account-search-outline</v-icon>
                        Search
                    </v-btn>
            </v-col>
        </v-row>
    </base-card>
</template>

<script>
export default {
    emits: ['reset', 'search'],
    data() {
        return {
            menu: false,
            donateDate: {
                start: null,
                end: null,
            },
            donateDateClr: true,
            searchModel: {}
        }
    },
    computed: {
        donateDateText () {
            return this.donateDateClr ? '' : `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.start)}-${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.end)}`;
        },
        donateDateTooltip () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.start)}  to  ${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.end)}`;
        },
    },
    mounted() {
        this.donateDate = {
            start:  this.$helpers.getJsDateBeginEnd('start'),
            end: this.$helpers.getJsDateBeginEnd(),
        }
    },
    methods: {
        resetModel() {
            this.searchModel = {};
            this.donateDate ={
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
            this.donateDateClr = true;
            this.$emit('reset');
        },
        searchFilter() {
            this.searchModel.sd = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.donateDate.start);
            this.searchModel.ed = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.donateDate.end);
            this.$emit('search', this.searchModel);
        },
        clearDate(evt){
            if(!evt) {
                this.donateDateClr = true;
                this.donateDate = {
                    start:  this.$helpers.getJsDateBeginEnd('start'),
                    end: this.$helpers.getJsDateBeginEnd(),
                };
            }
        }
    }
}
</script>